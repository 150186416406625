import Vue from 'vue'
import './shared/plugins/storage'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import './plugins/editor'
import config from './config'
import router from './plugins/router'
import store from './plugins/store'
import './plugins/rights'
import App from './App.vue'
import Err from './Err.vue'

Vue.config.productionTip = false

config.mount(Vue.prototype, store)
    .then(() => {
        new Vue({
            store,
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    })
    .catch((err) => {
        Vue.prototype.currentError = err
        new Vue({
            vuetify,
            render: h => h(Err)
        }).$mount('#app')
    })
