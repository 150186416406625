export default {
    namespaced: true,

    state: {
        show: false,
        lot: {}
    },

    getters: {
        lot(state) {
            return state.lot
        },
        show(state) {
            return state.show
        }
    },

    mutations: {
        show(state, data) {
            if (!data.lot.image_url) {
                return
            }
            state.lot = Object.assign({}, data.lot)
            state.show = true
        },

        hide(state) {
            state.lot = {}
            state.show = false
        }
    }
}
