import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        token: Vue.prototype.$storage.get('cmpToken'),
        user: {},
        show: false
    },

    getters: {
        token(state) {
            return state.token
        },
        tokenHeader(state) {
            return `Bearer ${state.token}`
        },
        tokenQuery(state) {
            return `token=${state.token}`
        },
        connected(state) {
            return !!state.token
        },
        user(state) {
            return state.user
        },
        show(state) {
            return state.show
        }
    },

    mutations: {
        login(state, data) {
            state.token = data.token
            Vue.prototype.$storage.set('cmpToken', data.token)
        },
        logout(state) {
            state.token = null
            state.user = {}
            Vue.prototype.$storage.remove('cmpToken')
        },
        user(state, data) {
            state.user = data.user
        },
        show(state, data) {
            data = data || {}
            state.show = data.show !== undefined ? !!data.show : true
        }
    },

    actions: {
        login(context, data) {
            return Vue.prototype.$auth.post('/session?acl=cmp.view', data.user)
                .then((res) => {
                    const jwt = res.data.jwt
                    delete res.data.jwt
                    context.commit('user', { user: res.data })
                    context.commit('login', { token: jwt })
                })
        },
        logout(context) {
            return Vue.prototype.$auth.delete('/session')
                .then(() => context.commit('logout'))
        },
        ping(context) {
            return Vue.prototype.$auth.get('/session?acl=cmp.view')
                .then((res) => {
                    const jwt = res.data.jwt
                    delete res.data.jwt
                    context.commit('user', { user: res.data })
                    if (!context.getters.token) {
                        context.commit('login', { token: jwt })
                    }
                })
        }
    }
}
