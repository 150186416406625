
const lotDefault = { owner: {} }

export default {
    namespaced: true,

    state: {
        lots: [],
        current: Object.assign({}, lotDefault)
    },

    getters: {
        list(state) {
            return state.lots
        },
        current(state) {
            return state.current
        }
    },

    mutations: {
        list(state, data) {
            state.lots = data.lots
        },
        addList(state, data) {
            state.lots = state.lots.concat(data.lots)
        },
        read(state, data) {
            state.current = data.lot
        },
        update(state, data) {
            const lot = state.lots.find((l) => l.id === data.lot.id)
            if (lot) {
                lot.cmp = Object.assign({}, data.cmp)
            }
        }
    }
}
