<template>
    <v-btn class="mt-0 mr-0"
        color="primary"
        :text="!value"
        @click="toggle">
        Tous les lots
    </v-btn>
</template>

<script>
export default {
    name: 'cmp-sale-all-btn',
    props: {
        value: { type: Boolean }
    },
    methods: {
        toggle() {
            this.$emit('input', !this.value)
        }
    }
}
</script>
