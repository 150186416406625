<template>
    <v-card>
        <v-form ref="form" v-model="valid" lazy-validation @submit.stop.prevent="submit">
            <v-card-title>
                <div>
                    <h5 class="headline">{{ lot.stock_no }}</h5>
                    <strong>{{ lot.owner.code }}</strong> - {{ lot.owner.name }}
                </div>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col sm="4" md="3">
                            <v-img @click="showImage" :class="{more: hasImage}" :src="lotImage" />
                        </v-col>
                        <v-col sm="8" md="9">
                            <v-text-field
                                v-model="lot.title"
                                label="Titre"
                                ref="focus" />

                            <cmp-text-html v-if="lot.id"
                                v-model="lot.description"
                                label="Description" />

                            <div v-html="cmp.notes || 'Pas de commentaire CMP'" class="mb-3"></div>

                            <cmp-status-btn v-model="cmp.status" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn text
                    color="accent"
                    @click="cancel">
                    <v-icon left>$vuetify.icons.baseBack</v-icon>
                    Retour
                </v-btn>
                <v-spacer />
                <v-btn text
                    color="primary"
                    :disabled="!valid"
                    :loading="loading"
                    type="submit">
                    <v-icon left>$vuetify.icons.baseSave</v-icon>
                    Enregistrer
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import CmpStatusBtn from '@/components/CmpStatusBtn'
import CmpTextHtml from '@/components/CmpTextHtml'

export default {
    name: 'cmp-admin-lot-form',
    components: { CmpStatusBtn, CmpTextHtml },
    props: {
        id: { type: [String, Number] }
    },
    data: () => ({
        valid: false,
        loading: false,
        cmp: {}
    }),
    computed: {
        lotImage() {
            return this.lot.image_url || '/lot.jpg'
        },
        lot() {
            const data = this.$store.getters['lot/current']
            this.$store.commit('upd/current', { lot: data, cmp: this.cmp })
            return data
        },
        hasImage() {
            return !!this.lot.image_url
        }
    },
    watch: {
        $route(v) {
            v && this.load()
        }
    },
    mounted() {
        this.load()
    },
    methods: {

        load() {
            this.loading = true
            this.cmp = {}
            this.lot.id = null
            return this.$api.get(`/cmp/admin/${this.id}`)
                .then((res) => {
                    this.$store.commit('lot/read', { lot: res.data })
                    this.cmp = Object.assign({}, this.lot.cmp)
                    this.$store.commit('upd/initial', { lot: this.lot, cmp: this.cmp })
                })
                .then(() => this.$refs.focus.focus())
                .finally(() => this.loading = false)
        },

        showImage() {
            this.$store.commit('img/show', { lot: this.lot })
        },

        cancel() {
            this.$router.push({ name: 'lot' })
        },

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            this.lot.status = this.cmp.status === null ? 'null' : this.cmp.status
            return this.$api.put(`/cmp/admin/${this.id}`, this.lot)
                .then(() => {
                    this.$store.commit('msg/show', { message: 'Enregistré' })
                    this.$store.commit('lot/update', { lot: this.lot, cmp: this.cmp })
                    this.$store.commit('upd/initial', { lot: this.lot, cmp: this.cmp })
                })
                .finally(() => this.loading = false)
        }
    }
}
</script>

<style lang="sass" scoped>
.more
    cursor: pointer

</style>
