<template>
    <v-container fluid>
        <v-row dense class="cmp-lot-list">
            <v-col lg="12">
                <v-text-field
                    v-model="search"
                    label="Chercher un lot"
                    clearable
                    prepend-icon="$vuetify.icons.baseSearch"
                    autofocus/>
            </v-col>
            <v-col lg="12" v-show="lots.length">
                <v-card>
                    <v-list two-line>
                        <template v-for="(lot, index) in lots">
                            <cmp-lot-list-item
                                :key="lot.id"
                                :lot="lot" />

                            <v-divider
                                :key="index"
                                v-if="index + 1 < lots.length" />
                        </template>
                    </v-list>
                </v-card>
            </v-col>
            <v-col lg="12" v-if="!lots.length && !loading" class="text-center">
                <p>Aucun lot trouvé</p>
            </v-col>
            <v-col lg="12" v-show="loading" class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CmpLotListItem from '@/components/CmpLotListItem'
import lodash from 'lodash'

export default {
    name: 'cmp-lot-list',
    components: { CmpLotListItem },
    data: () => ({
        search: '',
        limit: 8,
        loading: false
    }),
    computed: {
        lots() {
            return this.$store.getters['lot/list']
        }
    },
    watch: {
        search(v) {
            v ? this.loadBounce() : this.clear()
        }
    },
    methods: {
        load() {
            if (!this.search) {
                return
            }
            this.loading = true
            return this.$api.get(`/cmp/lots?limit=${this.limit}&q=${this.search}`)
                .then((res) => this.$store.commit('lot/list', { lots: res.data }))
                .finally(() => this.loading = false)
        },
        clear() {
            this.$store.commit('lot/list', { lots: [] })
        },
        loadBounce: lodash.debounce(function() {
            this.load()
        }, 700)
    }
}
</script>
