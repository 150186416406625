<template>
    <v-list-item router :to="{ name: 'lotform', params: { id: lot.id } }" :color="cmpColor">
        <v-list-item-avatar>
            <v-img :src="lotImage" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ lot.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ lot.stock_no }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'cmp-lot-list-item',
    props: {
        lot: { type: Object }
    },
    computed: {
        lotImage() {
            return this.lot.image_url || '/lot.jpg'
        },
        cmpColor() {
            if (!this.lot.cmp || this.lot.cmp.status === null) {
                return
            }
            return this.lot.cmp.status ? 'success' : 'error'
        }
    }
}
</script>
