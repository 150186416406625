<template>
    <v-app-bar app dense flat color="primary">
        <v-img src="/piguet.svg" :max-width="150" class="px-5" />
        <v-toolbar-title color="info" class="white--text px-5">
            <span>CMP</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon text dark @click="drawer = true">
            <v-icon>$vuetify.icons.baseMenuVert</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
export default {
    name: 'cmp-top-toolbar',
    props: {
        value: { type: Boolean }
    },
    computed: {
        drawer: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
