<template>
    <v-container fluid>
        <h5>Page introuvable</h5>
    </v-container>
</template>

<script>
export default {
}
</script>
