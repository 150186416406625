<template>
    <div class="html-container mb-4">
        <div :id="id" v-html="content"></div>
    </div>
</template>

<script>
import Quill from 'quill'

let id = 0

export default {
    name: 'cmp-text-html',
    props: {
        value: { type: String, default: '' },
        label: { type: String }
    },
    data() {
        id += 1
        return {
            id: `cmp-html-${id}`,
            editor: null,
            content: this.value
        }
    },
    mounted() {
        this.editor = new Quill(`#${this.id}`, {
            modules: {
                toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                ]
            },
            placeholder: this.label,
            theme: 'snow'
        })
        this.editor.on('text-change', (d, od, txt) => {
            this.$emit('input', this.editor.getHtml())
        })
    }
}
</script>

<style lang="sass">
.html-container
    clear: both

</style>
