<template>
    <v-list class="pt-0">
        <v-toolbar flat dense color="grey lighten-3">
            <v-spacer />
            <v-btn icon text @click="show = false" color="primary">
                <v-icon>$vuetify.icons.baseClose</v-icon>
            </v-btn>
        </v-toolbar>
        <p class="title pa-3">Bienvenue {{ connected.prenom }}</p>
        <template v-for="(item, index) in items">
            <v-divider v-if="item.divider" :key="index" />
            <v-list-item v-else
                :key="item.text"
                :to="item.to"
                v-rights="item.rights"
                @click="(item.click && item.click(item)) || null">
                <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    name: 'cmp-menu-variant',
    props: {
        value: { type: Boolean }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        connected() {
            return this.$store.getters['auth/user']
        },
        items() {
            return [
                { to: 'lot', rights: ['cmp', 'view'], icon: '$vuetify.icons.lotList', text: 'Lots' },
                { divider: true },
                { click: this.logout, rights: ['cmp', 'view'], icon: '$vuetify.icons.baseBack', text: 'Logout' }
            ]
        }
    },
    methods: {
        logout() {
            return this.$store.dispatch('auth/logout')
                .then(() => this.$router.push({ name: 'login' }))
        }
    }
}
</script>
