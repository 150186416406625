import Vue from 'vue'

let sale = null
try {
    sale = JSON.parse(Vue.prototype.$storage.get('cmpSale')) || {}
} catch (e) {
    sale = {}
}

export default {
    namespaced: true,

    state: {
        sale: sale
    },

    getters: {
        sale(state) {
            return state.sale
        }
    },

    mutations: {
        setSale(state, data) {
            state.sale = {
                id: data.sale.id,
                title: data.sale.title
            }
            Vue.prototype.$storage.set('cmpSale', JSON.stringify(state.sale))
        }
    }
}
