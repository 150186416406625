<template>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="pa-5" :class="{ 'centered': true }">
        <v-row>
            <v-col sm="5" v-show="listActive">
                <cmp-admin-lot-list v-if="staff" />
                <cmp-lot-list v-else />
            </v-col>
            <v-col sm="7" v-show="formActive">
                <router-view />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CmpAdminLotList from '@/components/CmpAdminLotList'
import CmpLotList from '@/components/CmpLotList'
import ListFormMixin from '@/mixins/ListFormMixin'

export default {
    components: { CmpLotList, CmpAdminLotList },
    mixins: [ ListFormMixin ],
    data: () => ({
        formRoute: 'lotform'
    }),
    computed: {
        staff() {
            return this.$rights(['nx', 'view'])
        }
    }
}
</script>

<style lang="sass" scoped>
.centered
    max-width: 1785px

</style>
