<template>
    <v-container fluid class="pt-0">
        <v-row dense class="cmp-lot-list">
            <v-col xs="12" lg="12">
                <cmp-sale-selector :all="all" @all="v => all = v" />
            </v-col>
            <v-col xs="12" sm="12" md="5">
                <v-text-field
                    v-model="search"
                    label="Chercher un lot"
                    clearable
                    prepend-icon="$vuetify.icons.baseSearch"
                    autofocus
                    hide-details
                    single-line />
            </v-col>
            <v-col sm="8" md="5">
                <cmp-status-selector v-model="cmp" />
            </v-col>
            <v-col sm="4" md="2">
                <cmp-vx-vs-selector v-model="vtype" />
            </v-col>
            <v-col lg="12" v-show="lots.length">
                <v-card>
                    <v-list three-line>
                        <template v-for="(lot, index) in lots">
                            <cmp-admin-lot-list-item
                                :key="lot.id"
                                :lot="lot" />

                            <v-divider
                                :key="index"
                                v-if="index + 1 < lots.length" />
                        </template>
                        <v-list-item v-show="more">
                            <v-btn @click="load(true)" block text color="primary">
                                <v-icon>$vuetify.icons.lotListExpand</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col lg="12" v-if="!lots.length && !loading" class="text-center">
                <p>Aucun lot trouvé</p>
            </v-col>
            <v-col lg="12" v-show="loading" class="text-center">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CmpAdminLotListItem from '@/components/CmpAdminLotListItem'
import CmpSaleSelector from '@/components/CmpSaleSelector'
import CmpStatusSelector from '@/components/CmpStatusSelector'
import CmpVxVsSelector from '@/components/CmpVxVsSelector'
import lodash from 'lodash'

export default {
    name: 'cmp-admin-lot-list',
    components: { CmpAdminLotListItem, CmpSaleSelector, CmpStatusSelector, CmpVxVsSelector },
    data: () => ({
        search: '',
        limit: 20,
        loading: false,
        cmp: undefined,
        all: false,
        vtype: null
    }),
    computed: {
        lots() {
            return this.$store.getters['lot/list']
        },
        more() {
            return this.lots.length % this.limit === 0
        },
        cmpquery() {
            if (this.cmp === null) {
                return 'null'
            }
            return this.cmp ? 1 : 0
        },
        sale() {
            return this.$store.getters['sale/sale']
        }
    },
    watch: {
        search(v) {
            this.loadBounce()
        },
        cmp() {
            this.load()
        },
        vtype() {
            this.load()
        },
        all() {
            this.load()
        },
        sale(v) {
            v && this.load()
        }
    },
    methods: {
        load(add) {
            this.loading = true
            if (!add) {
                this.$store.commit('lot/list', { lots: [] })
            }
            let q = `start=${this.lots.length}&limit=${this.limit}&q=${this.search || ''}&s=${this.sale.id}&all=${this.all ? 1 : 0}`
            if (this.cmp !== undefined) {
                q += `&cmp=${this.cmpquery}`
            }
            if (this.vtype !== null) {
                q += `&t=${this.vtype ? 1 : 0}`
            }
            return this.$api.get(`/cmp/admin?${q}`)
                .then((res) => {
                    this.$store.commit(`lot/${add ? 'addList' : 'list'}`, { lots: res.data })
                })
                .finally(() => this.loading = false)
        },
        loadBounce: lodash.debounce(function() {
            this.load()
        }, 700)
    }
}
</script>
