<template>
    <v-list-item router :to="{ name: 'lotformadmin', params: { id: lot.id } }" :class="cmpClass">
        <v-list-item-avatar>
            <v-img :src="lotImage" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title :class="cmpClass">{{ lot.title }}</v-list-item-title>
            <v-list-item-subtitle>
                <strong>{{ lot.owner.code }}</strong>
                - {{ lot.owner.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ lot.stock_no }}</v-list-item-subtitle>
            <v-list-item-subtitle>
                <strong>{{ lot.sale && lot.sale.title }}</strong>
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'cmp-admin-lot-list-item',
    props: {
        lot: { type: Object }
    },
    computed: {
        lotImage() {
            return this.lot.image_url || '/lot.jpg'
        },
        cmpColor() {
            if (!this.lot.cmp || this.lot.cmp.status === null) {
                return
            }
            return this.lot.cmp.status ? 'success' : 'error'
        },
        cmpClass() {
            if (!this.cmpColor) {
                return
            }
            return this.cmpColor === 'success'
                ? 'secondary--text'
                : 'error--text'
        }
    }
}
</script>
