<template>
    <v-dialog v-model="show" width="500">
        <cmp-login @login="show = false" />
    </v-dialog>
</template>

<script>
import CmpLogin from '@/components/CmpLogin'

export default {
    name: 'cmp-login-popup',
    components: { CmpLogin },
    computed: {
        show: {
            get() {
                return this.$store.getters['auth/show']
            },
            set(v) {
                this.$store.commit('auth/show', { show: v })
            }
        }
    }
}
</script>
