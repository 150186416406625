import Vue from 'vue'

const StorageLocal = {

    set(key, value) {
        this.storage().setItem(key, value)
    },

    get(key) {
        return this.storage().getItem(key)
    },

    remove(key) {
        this.storage().removeItem(key)
    },

    available() {
        const mod = '___voilabhdvstorage___'
        try {
            this.set(mod, mod)
            this.remove(mod)
            return true
        } catch (e) {
            return false
        }
    },

    storage() {
        return window.localStorage
    }
}

const MockStorage = {
    _storage: {},

    set(key, value) {
        this.storage()[key] = value
    },

    get(key) {
        return this.storage()[key]
    },

    remove(key) {
        this.storage()[key] = undefined
    },

    available() {
        return false
    },

    storage() {
        return this._storage
    }
}

Vue.prototype.$storage = StorageLocal.available() ? StorageLocal : MockStorage

export default StorageLocal
