'use strict'

import Vue from 'vue'
import store from './store'
import Axios from 'axios'
import AxiosFinally from 'promise.prototype.finally'

Axios.defaults.withCredentials = true
Axios.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest'
}
const authInterceptor = function(req) {
    const token = store.getters['auth/tokenHeader']
    if (token) {
        req.headers.Authorization = token
    }
    return req
}
Axios.interceptors.request.use(authInterceptor)
// pour gérer le .finally
AxiosFinally.shim()

Vue.prototype.$api = Axios.create({
    baseURL: '' // sera configuré lors du boot
})
Vue.prototype.$api.interceptors.request.use(authInterceptor)

Vue.prototype.$auth = Axios.create({
    baseURL: '' // sera configuré lors du boot
})
Vue.prototype.$auth.interceptors.request.use(authInterceptor)

Vue.prototype.$http = Axios

export default Axios
