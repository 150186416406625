<template>
    <div>
        <v-snackbar v-for="msg in messages" :key="msg.id"
            :top="true"
            :timeout="msg.timeout"
            :color="msg.color"
            :multi-line="true"
            v-model="msg.show">
            {{ msg.message }}
            <v-btn light text icon @click="msg.show = false">
                <v-icon>$vuetify.icons.baseClose</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'cmp-message',
    computed: {
        messages() {
            return this.$store.getters['msg/messages']
        }
    }
}
</script>
