import Axios from 'axios'
import moment from 'moment'

export default {

    mount(vue, store) {
        return Axios.get('/config.json')
            .then((res) => {
                const config = (res && res.data) || {}
                Axios.defaults.baseURL = config.url
                vue.$api.defaults.baseURL = `${config.url}/api`
                vue.$auth.defaults.baseURL = config.url
                vue.$config = config
                moment.locale('fr')
                return store.dispatch('auth/ping')
            })
            .catch(() => store.commit('auth/logout'))
    }
}
