<template>
    <v-autocomplete
        v-model="sale"
        :items="sales"
        :loading="loading"
        :search-input.sync="search"
        hide-no-data
        item-text="title"
        item-value="id"
        label="Vente courante"
        prepend-icon="$vuetify.icons.saleSelector"
        single-line
        hide-details
        dense
        return-object>
        <template #append-outer>
            <cmp-sale-all-btn v-model="allSales" />
        </template>
    </v-autocomplete>
</template>

<script>
import CmpSaleAllBtn from '@/components/CmpSaleAllBtn'
import lodash from 'lodash'

export default {
    name: 'cmp-sale-selector',
    components: { CmpSaleAllBtn },
    props: {
        all: { type: Boolean }
    },
    data: () => ({
        sales: [],
        loading: false,
        search: ''
    }),
    computed: {
        allSales: {
            get() {
                return this.all
            },
            set(v) {
                this.$emit('all', v)
            }
        },

        sale: {
            get() {
                return this.$store.getters['sale/sale']
            },
            set(s) {
                this.$store.commit('sale/setSale', { sale: s })
            }
        }
    },
    watch: {
        search(v) {
            v && this.loadBounce()
        }
    },
    mounted() {
        if (!this.sales.length && this.sale) {
            this.sales.push(Object.assign({}, this.sale))
        }
    },
    methods: {
        load() {
            this.loading = true
            this.$api.get(`/sales?q=${this.search || ''}`)
                .then((res) => this.sales = res.data)
                .finally(() => this.loading = false)
        },
        loadBounce: lodash.debounce(function() {
            this.load()
        }, 700)
    }
}
</script>
