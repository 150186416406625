import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import lodash from 'lodash'
import vuetifyconfig from '@/shared/plugins/vuetify'

Vue.use(Vuetify)

export default new Vuetify(lodash.merge(vuetifyconfig, {
    theme: {
        themes: {
            light: {
                primary: '#009900',
                secondary: '#44cc44',
                accent: '#66dd66'
            }
        }
    },
    icons: {
        values: {
            // base
            baseSearch: 'mdi-magnify',
            baseSave: 'mdi-content-save',
            baseBack: 'mdi-undo',
            baseClose: 'mdi-close',
            baseMenuVert: 'mdi-dots-vertical',
            // lots
            lotList: 'mdi-format-list-bulleted-square',
            lotListExpand: 'mdi-chevron-down',
            // sales
            saleSelector: 'mdi-bank'
        }
    }
}))
