import Vue from 'vue'
import Router from 'vue-router'
import Lot from '@/views/Lot'
import Login from '@/views/Login'
import NotFound from '@/views/NotFound'
import CmpLotForm from '@/components/CmpLotForm'
import CmpAdminLotForm from '@/components/CmpAdminLotForm'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/lot',
            name: 'lot',
            component: Lot,
            children: [
                {
                    path: ':id',
                    name: 'lotform',
                    component: CmpLotForm,
                    props: true
                },
                {
                    path: 'admin/:id',
                    name: 'lotformadmin',
                    component: CmpAdminLotForm,
                    props: true
                }
            ]
        },
        {
            path: '*',
            name: 'notfound',
            component: NotFound
        }
    ]
})

router.beforeEach((to, from, next) => {
    const connected = store.getters['auth/connected']

    if (to.name !== 'login' && !connected) {
        return next({ path: `/?p=${to.path}` })
    }
    if (to.name === 'login' && connected) {
        return next({ name: 'lot' })
    }
    return next()
})

router.beforeEach((to, from, next) => {
    if (store.getters['upd/dirty']) {
        if (window.confirm("Vous n'avez pas enregistré! Vous perdrez vos modifications en continuant. Continuer?")) {
            store.commit('upd/initial', '')
            return next()
        }
        return next(false)
    }
    next()
})

export default router
