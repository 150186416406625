import Vue from 'vue'
import Vuex from 'vuex'
import CmpAuthStore from '@/stores/CmpAuthStore'
import CmpLotStore from '@/stores/CmpLotStore'
import CmpMessageStore from '@/stores/CmpMessageStore'
import CmpSaleStore from '@/stores/CmpSaleStore'
import CmpImageStore from '@/stores/CmpImageStore'
import CmpUpdateStore from '@/stores/CmpUpdateStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: CmpAuthStore,
        lot: CmpLotStore,
        msg: CmpMessageStore,
        sale: CmpSaleStore,
        img: CmpImageStore,
        upd: CmpUpdateStore
    }
})
