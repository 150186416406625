import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'

export default {
    theme: {
        themes: {
            light: {
                primary: '#05B7FE',
                secondary: '#499CAE',
                accent: '#FF004F'
            }
        }
    },
    icons: {
        iconfont: 'mdi',
        values: {
            // base
            baseHelp: 'mdi-help-circle',
            baseHelpOn: 'mdi-help-circle-outline',
            baseInfo: 'mdi-information',
            baseSave: 'mdi-content-save',
            baseClose: 'mdi-close',
            baseDelete: 'mdi-delete',
            baseDeleteConfirm: 'mdi-check',
            baseBack: 'mdi-chevron-left',
            baseMenu: 'mdi-menu',
            baseMenuVert: 'mdi-dots-vertical',
            baseAdd: 'mdi-plus',
            baseEdit: 'mdi-pencil',
            baseDown: 'mdi-chevron-down',
            baseUp: 'mdi-chevron-up',
            baseRight: 'mdi-chevron-right',
            baseLeft: 'mdi-chevron-left',
            baseMenuLeft: 'mdi-menu-left',
            baseMenuRight: 'mdi-menu-right',
            baseSearch: 'mdi-magnify'
        }
    },
    lang: {
        locales: { en, fr },
        current: 'fr'
    }
}
