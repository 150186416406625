export default {
    namespaced: true,

    state: {
        initial: '',
        current: ''
    },

    getters: {
        dirty(state) {
            return state.initial && state.current && state.initial !== state.current
        }
    },

    mutations: {
        initial(state, data) {
            state.initial = data ? JSON.stringify(data) : ''
            state.current = ''
        },

        current(state, data) {
            state.current = data ? JSON.stringify(data) : ''
        }
    }
}
