<template>
    <v-toolbar dense flat class="grey lighten-4">
        <div class="cmp-footer--text">
            <a href="https://nx.piguet.com" target="_blank" v-rights="['nx', 'view']">Nx</a>
            &copy;{{ year }},
            <a href="https://www.piguet.com" target="_blank">Piguet.com</a>,
            <a href="https://www.voilab.ch" target="_blank">Voilab</a>
        </div>
    </v-toolbar>
</template>

<script>
import moment from 'moment'

export default {
    name: 'cmp-footer',
    data: () => ({
        year: moment().format('YYYY')
    })
}
</script>

<style lang="sass" scoped>
.cmp-footer--text
    font-size: 14px

</style>
