<template>
    <v-select
        v-model="cmp"
        :items="cmps"
        item-value="id"
        item-text="value"
        label="Statut de conformité"
        hide-details
        single-line />
</template>

<script>
export default {
    name: 'cmp-status-selector',
    props: {
        value: { type: Boolean, default: undefined }
    },
    data: () => ({
        cmps: [
            { id: true, value: 'Conforme' },
            { id: false, value: 'Non conforme' },
            { id: null, value: 'Non traité' }
        ]
    }),
    computed: {
        cmp: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
