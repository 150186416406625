<template>
    <v-card>
        <v-form ref="form" v-model="valid" lazy-validation @submit.stop.prevent="submit">
            <v-card-text>
                <h5 class="headline mb-4">Contrôle des Métaux Précieux</h5>

                <v-text-field
                    v-model="auth.login"
                    label="Login"
                    :rules="requiredRules"
                    autofocus
                    required />

                <v-text-field
                    v-model="auth.password"
                    label="Mot de passe"
                    type="password"
                    :rules="requiredRules"
                    required />
            </v-card-text>
            <v-card-actions>
                <v-btn text block
                    color="primary"
                    :disabled="!valid"
                    :loading="loading"
                    type="submit">
                    Se connecter</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: 'cmp-login',
    data() {
        return {
            valid: false,
            loading: false,
            auth: {
                login: '',
                password: ''
            },
            requiredRules: [
                v => !!v || 'Champ obligatoire'
            ]
        }
    },
    methods: {

        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$store.dispatch('auth/login', { user: this.auth })
                .then(() => this.$emit('login'))
                .finally(() => this.loading = false)
        }
    }
}
</script>
