'use strict'

import lodash from 'lodash'

const defaults = {
    info: {
        timeout: 2000,
        color: 'accent'
    },
    error: {
        timeout: 8000,
        color: 'error'
    }
}

export default {
    namespaced: true,

    state: {
        id: 0,
        messages: []
    },

    getters: {
        messages: (state) => state.messages
    },

    mutations: {
        show(state, data) {
            state.id += 1
            if (state.id > 10000) {
                state.id = 1
            }
            if (!data.type || !defaults[data.type]) {
                data.type = 'info'
            }
            state.messages.push(Object.assign({}, defaults[data.type], data, {
                id: state.id,
                show: true
            }))
            lodash.remove(state.messages, (m) => !m.show)
        }
    }
}
