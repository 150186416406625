export default {
    data: () => ({
        listActive: true,
        formActive: false,
        formRoute: ''
    }),
    mounted() {
        if (this.$route.name.indexOf(this.formRoute) === 0) {
            this.$nextTick(() => this.manage(1, 0))
        }
    },
    watch: {
        $route(to, from) {
            this.manage(to.path.split('/').length, from.path.split('/').length)
        }
    },
    methods: {
        manage(to, from) {
            this.listActive = this.$vuetify.breakpoint.mdAndUp || to < from
            this.formActive = this.$vuetify.breakpoint.mdAndUp || !this.listActive
        }
    }
}
