<template>
    <v-container>
        <v-row>
            <v-col xs="12" sm="6" offset-sm="3" md="4" offset-md="4">
                <v-img src="/piguet.svg" />
            </v-col>
            <v-col xs="12" sm="6" offset-sm="3" md="4" offset-md="4">
                <cmp-login @login="login()" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CmpLogin from '@/components/CmpLogin'

export default {
    components: { CmpLogin },
    methods: {
        login() {
            if (this.$route.query.p) {
                return this.$router.push({ path: this.$route.query.p })
            }
            this.$router.push({ name: 'lot' })
        }
    }
}
</script>
