<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col xs="6">
                <v-btn large block
                    @click="toggle(false)"
                    :color="!loading && status !== true ? 'error' : 'grey lighten-1'"
                    :outlined="status !== false">
                    Non conforme</v-btn>
            </v-col>
            <v-col xs="6">
                <v-btn large block
                    @click="toggle(true)"
                    :color="!loading && status !== false ? 'success' : 'grey lighten-1'"
                    :outlined="status !== true">
                    Conforme</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'cmp-status-btn',
    props: {
        value: { type: Boolean, default: null }
    },
    computed: {
        loading() {
            return this.$store.getters['load/loading']
        },
        status: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        toggle(state) {
            if (this.status !== state) {
                this.status = state
            } else {
                this.status = null
            }
        }
    }
}
</script>
