'use strict'

import Vue from 'vue'
import store from './store'

const bind = function(el, binding) {
    const rights = binding.value

    let ok = false
    if (store.getters['auth/user'] && store.getters['auth/user'].roles) {
        ok = store.getters['auth/user'].roles[rights[0]] && store.getters['auth/user'].roles[rights[0]][rights[1]]
    }
    el.style.display = ok ? '' : 'none'
}

const Plugin = function() {
    Vue.prototype.$rights = function(value) {
        const obj = { style: {} }
        bind(obj, { value: value })
        return obj.style.display === ''
    }
}

Vue.directive('rights', {
    inserted: bind,
    bind: bind,
    update: bind
})

Vue.use({ install: Plugin })
