<template>
    <v-dialog v-model="show" scrollable fullscreen>
        <v-card class="popupimage" flat>
            <v-card-actions>
                <v-spacer />
                <p><strong>{{ lot.title }}</strong></p>
                <v-spacer />
                <v-btn @click="show = false" color="primary" icon text>
                    <v-icon>$vuetify.icons.baseClose</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <v-img :src="image" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'cmp-image-popup',
    computed: {
        show: {
            get() {
                return this.$store.getters['img/show']
            },
            set(v) {
                this.$store.commit('img/hide')
            }
        },
        lot() {
            return this.$store.getters['img/lot']
        },
        image() {
            return this.lot.image_url || '/lot.jpg'
        }
    }
}
</script>

<style lang="sass" scoped>
.popupimage
    background-color: rgba(255, 255, 255, 1)

</style>
