<template>
    <v-select
        v-model="type"
        :items="types"
        item-value="id"
        item-text="value"
        label="VX/VO"
        hide-details
        single-line />
</template>

<script>
export default {
    name: 'cmp-vx-vs-selector',
    props: {
        value: { type: Boolean, default: undefined }
    },
    data: () => ({
        types: [
            { id: true, value: 'VO' },
            { id: false, value: 'VX' },
            { id: null, value: 'VX/VO' }
        ]
    }),
    computed: {
        type: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
