<template>
    <v-app id="app">
        <v-navigation-drawer v-if="connected"
            class="cmp-navigation"
            app temporary fixed right
            disable-resize-watcher
            v-model="drawerRight">
            <cmp-menu-variant v-model="drawerRight" />
            <v-spacer />
            <cmp-footer />
        </v-navigation-drawer>
        <cmp-top-toolbar v-if="connected" v-model="drawerRight" />
        <v-main>
            <v-alert color="error" :value="!storageAvailable" dismissible>
                La configuration de votre navigateur ne permet pas d'enregistrer
                les préférences. Certains atouts (reconnexion automatique) ne
                sont dès lors pas fonctionnels. Merci de votre compréhension.
            </v-alert>
            <cmp-message />
            <cmp-login-popup />
            <cmp-image-popup />
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import CmpMessage from '@/components/CmpMessage'
import CmpLoginPopup from '@/components/CmpLoginPopup'
import CmpImagePopup from '@/components/CmpImagePopup'
import CmpFooter from '@/components/CmpFooter'
import CmpMenuVariant from '@/components/CmpMenuVariant'
import CmpTopToolbar from '@/components/CmpTopToolbar'
import Vue from 'vue'

export default {
    name: 'cmp',
    components: { CmpMessage, CmpLoginPopup, CmpImagePopup, CmpMenuVariant, CmpFooter, CmpTopToolbar },
    data: () => ({
        drawerRight: false
    }),
    computed: {
        connected() {
            return this.$store.getters['auth/connected']
        },
        storageAvailable() {
            return Vue.prototype.$storage.available()
        }
    },
    created() {
        const store = this.$store
        const errInterceptor = (err) => {
            const res = (err.response && err.response.data) || {}
            const msg = res.message || (err && err.message)
            const status = err.response && err.response.status
            if (status === 401 || status === 403) {
                if (this.$route.name !== 'login') {
                    this.$store.commit('auth/show')
                } else {
                    this.$store.commit('msg/show', {
                        message: "Vous n'avez pas les droits pour vous connecter",
                        type: 'error'
                    })
                }
            } else {
                this.$store.commit('msg/show', { message: msg, type: 'error' })
            }
            throw err
        }
        Vue.prototype.$api.interceptors.response.use(undefined, errInterceptor)
        Vue.prototype.$auth.interceptors.response.use(undefined, errInterceptor)
        Vue.prototype.$http.interceptors.response.use(undefined, errInterceptor)

        window.onbeforeunload = function() {
            if (store.getters['upd/dirty']) {
                return "Vous n'avez pas enregistré vos modifications! Veuillez les enregistrer avant de quitter."
            }
        }
    }
}
</script>

<style lang="sass">
.cmp-navigation

    .v-navigation-drawer__content
        display: flex !important
        flex-direction: column !important

    .spacer
        height: 100%

</style>
